import "./CodingProjectsPage.scss";
import { useState } from "react";
import MuseCarousel from "../../components/MuseCarousel/MuseCarousel";
import AircanadaCarousel from "../../components/AircanadaCarousel/AircanadaCarousel";
import RpsCarousel from "../../components/RpsCarousel/RpsCarousel";

export default function CodingProjectsPage() {
  const [museActiveSlide, setMuseActiveSlide] = useState(0);
  const [aircanadaActiveSlide, setAircanadaActiveSlide] = useState(0);
  const [rpsActiveSlide, setRpsActiveSlide] = useState(0);

  return (
    <div className="projects">
      <div className="projects__wrapper">
        <MuseCarousel
          activeSlide={museActiveSlide}
          setActiveSlide={setMuseActiveSlide}
        />
      </div>
      <div className="projects__wrapper">
        <AircanadaCarousel
          activeSlide={aircanadaActiveSlide}
          setActiveSlide={setAircanadaActiveSlide}
        />
      </div>
      <div className="projects__wrapper">
        <RpsCarousel
          activeSlide={rpsActiveSlide}
          setActiveSlide={setRpsActiveSlide}
        />
      </div>
    </div>
  );
}
