import "./AircanadaCarousel.scss";
import cssLogo from "../../assets/logos/CSS3_logo.png";
import expressLogo from "../../assets/logos/express-icon.svg";
import htmlLogo from "../../assets/logos/HTML5_Logo.svg";
import jsLogo from "../../assets/logos/JavaScript-logo.png";
import mysqlLogo from "../../assets/logos/mysql-logo.png";
import nodeLogo from "../../assets/logos/nodejs-logo.svg";
import reactLogo from "../../assets/logos/React-icon.svg";
import sassLogo from "../../assets/logos/Sass_Logo.svg";
import rightChevron from "../../assets/icons/right-arrow.png";
import leftChevron from "../../assets/icons/left-arrow.png";
import { useState, useEffect } from "react";

export default function AircanadaCarousel({ activeSlide, setActiveSlide }) {
  const [touchPosition, setTouchPosition] = useState(null);

  // Logic for page indicator
  useEffect(() => {
    const acDot1 = document.getElementById("acDot1");
    const acDot2 = document.getElementById("acDot2");

    switch (activeSlide) {
      case 0:
        acDot2.classList.remove("active");
        acDot1.classList.add("active");
        break;
      case 1:
        acDot1.classList.remove("active");
        acDot2.classList.add("active");
        break;
      default:
        break;
    }
  }, [activeSlide]);

  const leftNav = () => {
    if (activeSlide <= 0) {
      setActiveSlide(1);
      return;
    }
    setActiveSlide(activeSlide - 1);
  };

  const rightNav = () => {
    if (activeSlide >= 1) {
      setActiveSlide(0);
      return;
    }
    setActiveSlide(activeSlide + 1);
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      rightNav();
    }

    if (diff < -5) {
      leftNav();
    }

    setTouchPosition(null);
  };

  return (
    <section className="project">
      <div className="project__details">
        <h1 className="project__title project__title--AC">
          AirCanada Industry Project
        </h1>
        <p className="project__description project__description--AC">
          I had the privilege of proposing a solution to the challenge given to
          various teams of designers, data scientists, and developers by
          AirCanada. The challenge was to encourage frequent-flyer rewards
          members to interact with their rewards more consistently. Our solution
          was to implement a modal to gather user interest on the type of
          rewards. From there, the rewards page would accomodate their status
          and interests and display relevant rewards.
        </p>
        <h3 className="project__tech-header">Tech Stack Used</h3>
        <section className="project__techstack">
          <img className="tech" src={htmlLogo} alt="HTML logo" />
          <img className="tech" src={cssLogo} alt="CSS logo" />
          <img className="tech" src={sassLogo} alt="Sass logo" />
          <img className="tech" src={jsLogo} alt="JavaScript logo" />
          <img className="tech" src={reactLogo} alt="React logo" />
          <img className="tech" src={nodeLogo} alt="Node logo" />
          <img className="tech" src={expressLogo} alt="Express logo" />
          <img className="tech" src={mysqlLogo} alt="MySQL logo" />
        </section>
      </div>
      <hr className="linebreak" />
      <div className="project__carousel">
        <img
          className="carousel__nav carousel__nav--left"
          src={leftChevron}
          alt="Left Chevron"
          onClick={leftNav}
        />
        <section
          className="carousel__viewport"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className="carousel__inner"
            style={{ transform: `translateX(-${activeSlide * 100}%` }}
          >
            <article id="AC1" className="carousel__content"></article>
            <article id="AC2" className="carousel__content"></article>
          </div>
        </section>

        <img
          className="carousel__nav carousel__nav--right"
          src={rightChevron}
          alt="Right Chevron"
          onClick={rightNav}
        />

        <ul className="carousel__dots">
          <li
            id="acDot1"
            className="dot"
            onClick={() => {
              setActiveSlide(0);
            }}
          ></li>
          <li
            id="acDot2"
            className="dot"
            onClick={() => {
              setActiveSlide(1);
            }}
          ></li>
        </ul>
      </div>
    </section>
  );
}
