import "./Contact.scss";
import email from "../../assets/icons/email.png";
import github from "../../assets/logos/github-logo.svg";
import linkedin from "../../assets/logos/linkedinlogo.png";

export default function Contact() {
  return (
    <section className="contact" id="contact">
      <h3 className="contact__phrase">
        I'd love to connect with you to talk about tech and music opportunities!
      </h3>
      <div className="contact__container">
        <a className="logo__link" href="mailto:zealtiel@outlook.com">
          <img className="logo logo--email" src={email} alt="email" />
          <p className="logo__phrase">Send me an email!</p>
        </a>
        <a className="logo__link" href="https://github.com/ZeaZuniga">
          <img className="logo logo--git" src={github} alt="github" />
          <p className="logo__phrase">Checkout my projects on GitHub!</p>
        </a>
        <a
          className="logo__link"
          href="https://www.linkedin.com/in/zealtiel-zuniga-anaya"
        >
          <img className="logo logo--linkedin" src={linkedin} alt="linkedIn" />
          <p className="logo__phrase">Connect and chat with me on LinkedIn!</p>
        </a>
      </div>
    </section>
  );
}
