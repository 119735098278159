import "./About.scss";
import headshot from "../../assets/images/zealtiel-headshot.jpeg";
import cssLogo from "../../assets/logos/CSS3_logo.png";
import expressLogo from "../../assets/logos/express-icon.svg";
import gitOrange from "../../assets/logos/Git-Icon-Orange.png";
import htmlLogo from "../../assets/logos/HTML5_Logo.svg";
import jsLogo from "../../assets/logos/JavaScript-logo.png";
import mysqlLogo from "../../assets/logos/mysql-logo.png";
import nodeLogo from "../../assets/logos/nodejs-logo.svg";
import reactLogo from "../../assets/logos/React-icon.svg";
import sassLogo from "../../assets/logos/Sass_Logo.svg";

export default function About() {
  return (
    <section className="about">
      <div id="techStack" className="about__tech-stack">
        <h3 className="about__header about__header--skills">Skillset</h3>
        <ul className="tech__list">
          <li className="tech__item">
            <img className="logo logo--large" src={htmlLogo} alt="HTML5 Logo" />
            HTML5
          </li>
          <li className="tech__item">
            <img className="logo logo--large" src={cssLogo} alt="CSS3 Logo" />
            CSS3
          </li>
          <li className="tech__item">
            <img className="logo" src={sassLogo} alt="Sass Logo" />
            Sass
          </li>
          <li className="tech__item">
            <img
              className="logo logo--large"
              src={jsLogo}
              alt="JavaScript Logo"
            />
            JavaScript
          </li>
          <li className="tech__item">
            <img
              className="logo logo--large"
              src={reactLogo}
              alt="React Logo"
            />
            React
          </li>
          <li className="tech__item">
            <img
              className="logo logo--large"
              src={nodeLogo}
              alt="Node JS Logo"
            />
            Node.js
          </li>
          <li className="tech__item">
            <img className="logo" src={expressLogo} alt="Express JS Logo" />
            Express
          </li>
          <li className="tech__item">
            <img className="logo" src={mysqlLogo} alt="MySQL Logo" />
            MySQL
          </li>
          <li className="tech__item">
            <img className="logo logo--large" src={gitOrange} alt="Git Logo" />
            Git
          </li>
        </ul>
      </div>
      <div className="about__face-quote" id="about">
        <img
          className="about__face-img"
          src={headshot}
          alt="Zealtiel Headshot"
        />
        <section className="about__text-container">
          <h3 className="about__header about__header--about">About Zea</h3>
          <p className="about__phrase">
            I am based in Arkansas with a background in music performance. In
            the music industry, I became a jack of all trades; teaching myself
            how to play over 10 instruments, compose, record, and produce songs,
            and understanding how each skillset relates to the others. Now as I
            switch careers into software development, I carry the same mindset:
            to learn as many skills as I can, and to understand how each skill
            relates to the others to work together.
          </p>
        </section>
      </div>
      <div className="about__mission">
        <p className="mission__header">Mission Statement:</p>
        <p className="mission__quote">
          To be a full stack software developer dedicated to honoring God
          through the pursuit of self-education and producing high quality work.
        </p>
      </div>
    </section>
  );
}
