import "./NavBar.scss";
import zunigaLogo from "../../assets/logos/ZunigaLogo.svg";
import { Link } from "react-router-dom";

export default function NavBar() {
  const scrollToSection = (section) => {
    setTimeout(() => {
      const selected = document.getElementById(section);
      if (selected) {
        selected.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  };

  const toggleNavbar = () => {
    const navbar = document.getElementById("nav");
    const logo = document.getElementById("navLogo");
    const list = document.getElementById("navList");
    const burger = document.getElementById("burger");

    navbar.classList.toggle("navbar__modal");
    logo.classList.toggle("navbar__hide");
    list.classList.toggle("navbar__hide");
    burger.classList.toggle("hamburger--active");
  };

  const linkReset = () => {
    const navbar = document.getElementById("nav");
    const logo = document.getElementById("navLogo");
    const list = document.getElementById("navList");
    const burger = document.getElementById("burger");

    navbar.classList.toggle("navbar__modal");
    logo.classList.add("navbar__hide");
    list.classList.add("navbar__hide");
    burger.classList.remove("hamburger--active");
  };

  return (
    <div className="navbar" id="nav">
      <Link
        id="navLogo"
        to="/"
        onClick={() => linkReset()}
        className="navbar__logo--container navbar__hide"
      >
        <img className="navbar__logo" src={zunigaLogo} alt="Zuniga Logo" />
      </Link>
      <ul id="navList" className="navbar__list navbar__hide">
        <li className="navbar__item">
          <Link to="/" onClick={() => linkReset()} className="navbar__link">
            Home
          </Link>
        </li>
        <li className="navbar__item">
          <Link
            to="/coding-projects"
            onClick={() => linkReset()}
            className="navbar__link"
          >
            Projects
          </Link>
        </li>
        <li className="navbar__item">
          <Link
            to="/#about"
            onClick={() => {
              scrollToSection("about");
              linkReset();
            }}
            className="navbar__link"
          >
            About
          </Link>
        </li>
        <li className="navbar__item">
          <Link
            to="/#contact"
            onClick={() => {
              scrollToSection("contact");
              linkReset();
            }}
            className="navbar__link"
          >
            Contact
          </Link>
        </li>
      </ul>

      <section
        onClick={toggleNavbar}
        id="burger"
        className="navbar__hamburger-wrapper"
      >
        <span className="hamburger hamburger__top"></span>
        <span className="hamburger hamburger__mid--1"></span>
        <span className="hamburger hamburger__mid--2"></span>
        <span className="hamburger hamburger__bottom"></span>
      </section>
    </div>
  );
}
