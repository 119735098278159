import "./HomePage.scss";
import chevDown from "../../assets/icons/down-arrow.png";
import About from "../../components/About/About";
import Contact from "../../components/Contact/Contact";

export default function HomePage() {
  const scrollToSection = (section) => {
    const selected = document.getElementById(section);
    if (selected) {
      selected.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="homepage">
      <section className="hero">
        <p className="hero__smalltext">Hey there, I am</p>
        <div className="hero__name-wrapper">
          <h1 className="name name-first">Zea</h1>
          <h1 className="name name-mid">ltiel</h1>
          <h1 className="name name-last">Zuniga</h1>
        </div>
        <img
          onClick={() => {
            scrollToSection("techStack");
          }}
          className="hero__chevDown"
          src={chevDown}
          alt="Chevron Down"
        />
      </section>
      <About />
      <Contact />
    </div>
  );
}
