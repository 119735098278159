import "./MuseCarousel.scss";
import cssLogo from "../../assets/logos/CSS3_logo.png";
import expressLogo from "../../assets/logos/express-icon.svg";
import htmlLogo from "../../assets/logos/HTML5_Logo.svg";
import jsLogo from "../../assets/logos/JavaScript-logo.png";
import mysqlLogo from "../../assets/logos/mysql-logo.png";
import nodeLogo from "../../assets/logos/nodejs-logo.svg";
import reactLogo from "../../assets/logos/React-icon.svg";
import sassLogo from "../../assets/logos/Sass_Logo.svg";
import rightChevron from "../../assets/icons/right-arrow.png";
import leftChevron from "../../assets/icons/left-arrow.png";
import { useState, useEffect } from "react";

export default function MuseCarousel({ activeSlide, setActiveSlide }) {
  const [touchPosition, setTouchPosition] = useState(null);

  // Logic for page indicator
  useEffect(() => {
    const museDot1 = document.getElementById("museDot1");
    const museDot2 = document.getElementById("museDot2");
    const museDot3 = document.getElementById("museDot3");
    const museDot4 = document.getElementById("museDot4");
    const museDot5 = document.getElementById("museDot5");

    switch (activeSlide) {
      case 0:
        museDot2.classList.remove("active");
        museDot3.classList.remove("active");
        museDot4.classList.remove("active");
        museDot5.classList.remove("active");

        museDot1.classList.add("active");
        break;
      case 1:
        museDot1.classList.remove("active");
        museDot3.classList.remove("active");
        museDot4.classList.remove("active");
        museDot5.classList.remove("active");

        museDot2.classList.add("active");
        break;
      case 2:
        museDot1.classList.remove("active");
        museDot2.classList.remove("active");
        museDot4.classList.remove("active");
        museDot5.classList.remove("active");

        museDot3.classList.add("active");
        break;
      case 3:
        museDot1.classList.remove("active");
        museDot2.classList.remove("active");
        museDot3.classList.remove("active");
        museDot5.classList.remove("active");

        museDot4.classList.add("active");
        break;
      case 4:
        museDot1.classList.remove("active");
        museDot2.classList.remove("active");
        museDot3.classList.remove("active");
        museDot4.classList.remove("active");

        museDot5.classList.add("active");
        break;
      default:
        break;
    }
  }, [activeSlide]);

  const leftNav = () => {
    if (activeSlide <= 0) {
      setActiveSlide(4);
      return;
    }
    setActiveSlide(activeSlide - 1);
  };

  const rightNav = () => {
    if (activeSlide >= 4) {
      setActiveSlide(0);
      return;
    }
    setActiveSlide(activeSlide + 1);
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      rightNav();
    }

    if (diff < -5) {
      leftNav();
    }

    setTouchPosition(null);
  };

  return (
    <section className="project">
      <div className="project__details">
        <h1 className="project__title">MuseFolio</h1>
        <p className="project__description">
          MuseFolio is a browser-based sheet music storage application and pdf
          reader which empowers musicians through consistent and easy access to
          their music library.
        </p>
        <h3 className="project__tech-header">Tech Stack Used</h3>
        <section className="project__techstack">
          <img className="tech" src={htmlLogo} alt="HTML logo" />
          <img className="tech" src={cssLogo} alt="CSS logo" />
          <img className="tech" src={sassLogo} alt="Sass logo" />
          <img className="tech" src={jsLogo} alt="JavaScript logo" />
          <img className="tech" src={reactLogo} alt="React logo" />
          <img className="tech" src={nodeLogo} alt="Node logo" />
          <img className="tech" src={expressLogo} alt="Express logo" />
          <img className="tech" src={mysqlLogo} alt="MySQL logo" />
        </section>
      </div>
      <hr className="linebreak" />
      <div className="project__carousel">
        <img
          className="carousel__nav carousel__nav--left"
          src={leftChevron}
          alt="Left Chevron"
          onClick={leftNav}
        />
        <section
          className="carousel__viewport"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <div
            className="carousel__inner"
            style={{ transform: `translateX(-${activeSlide * 100}%` }}
          >
            <article id="muse1" className="carousel__content"></article>
            <article id="muse2" className="carousel__content"></article>
            <article id="muse3" className="carousel__content"></article>
            <article id="muse4" className="carousel__content"></article>
            <article id="muse5" className="carousel__content"></article>
          </div>
        </section>

        <img
          className="carousel__nav carousel__nav--right"
          src={rightChevron}
          alt="Right Chevron"
          onClick={rightNav}
        />

        <ul className="carousel__dots">
          <li
            id="museDot1"
            className="dot"
            onClick={() => {
              setActiveSlide(0);
            }}
          ></li>
          <li
            id="museDot2"
            className="dot"
            onClick={() => {
              setActiveSlide(1);
            }}
          ></li>
          <li
            id="museDot3"
            className="dot"
            onClick={() => {
              setActiveSlide(2);
            }}
          ></li>
          <li
            id="museDot4"
            className="dot"
            onClick={() => {
              setActiveSlide(3);
            }}
          ></li>
          <li
            id="museDot5"
            className="dot"
            onClick={() => {
              setActiveSlide(4);
            }}
          ></li>
        </ul>
      </div>
    </section>
  );
}
